<template>
  <div class="p-1">
    <div
      v-if="!electionOpen"
      class="text-center"
    >
      <b-img
        class="mt-5"
        rounded
        :src="require('../assets/images/logo/logo.png')"
        alt="IEPC"
      />
      <h1
        class="my-5"
      >
        La votación no está abierta
      </h1>
    </div>
    <form-wizard
      v-if="acceptVote"
      ref="form"
      color="#7367F0"
      :title="`Elecciones ${electionYear} IEPC`"
      :subtitle="null"
      finish-button-text="Enviar voto"
      back-button-text="Anterior"
      next-button-text="Siguiente"
      class="steps-transparent mb-3"
      step-size="xs"
      @on-change="scrollToTop"
      @on-complete="formSubmitted"
    >

      <tab-content
        title="Datos personales"
        icon="feather icon-user"
        :before-change="validateName"
      >
        <b-card>
          <b-card-text>Por favor escriba su nombre completo</b-card-text>
          <b-card-text>
            <div class="d-flex flex-wrap">
              <div class="flex-fill mr-md-1 mb-md-0 mb-1">
                <b-form-input
                  v-model="firstNames"
                  placeholder="Nombres"
                  required
                  trim
                  :state="firstNamesError === ''"
                />
                <b-form-invalid-feedback class="text-danger">
                  {{ firstNamesError }}
                </b-form-invalid-feedback>
              </div>
              <div class="flex-fill">
                <b-form-input
                  v-model="lastNames"
                  placeholder="Apellidos"
                  required
                  trim
                  :state="lastNamesError === ''"
                />
                <b-form-invalid-feedback>
                  {{ lastNamesError }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-card-text>
          <!--          <b-card-footer>-->
          <!--            <div class="d-flex align-items-end flex-column">-->
          <!--              <b-button-->
          <!--                variant="success"-->
          <!--                size="lg"-->
          <!--              >-->
          <!--                Siguiente-->
          <!--              </b-button>-->
          <!--            </div>-->
          <!--          </b-card-footer>-->
        </b-card>
      </tab-content>

      <tab-content
        title="Candidatos a Consistorio"
        icon="feather icon-thumbs-up"
        :before-change="validateCandidates1"
      >
        <b-card>
          <b-card-text>Hola {{ firstNames }} {{ lastNames }}, seleccione {{ maxSelection1 }} opciones o menos</b-card-text>
          <b-card-text>
            <b-container>
              <b-row
                cols="1"
                cols-sm="2"
                cols-md="3"
                cols-lg="5"
              >
                <b-col
                  v-for="option in candidates1List"
                  :key="option.id"
                  sm
                  class="mb-4"
                >
                  <b-img-lazy
                    fluid-grow
                    rounded
                    :src="`https://firebasestorage.googleapis.com/v0/b/iepc-apps.appspot.com/o/consistorio%2F${option.id}.${option.imageExt}?alt=media`"
                    :alt="`No se ha recibido fotografía de ${option.name}`"
                    style="width: 35%; height: auto;"
                  />
                  <br>
                  <b-form-checkbox
                    v-model="candidates1"
                    :value="option.name"
                    size="lg"
                    switch
                  >
                    {{ option.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-container>
          </b-card-text>
          <b-card-footer>
            <div>
              {{ candidates1.length }} candidatos seleccionados de {{ candidates1List.length }} disponibles
            </div>
            <div
              v-if="candidates1.length > maxSelection1"
              class="text-danger"
            >
              Debe seleccionar {{ maxSelection1 }} candidatos o menos para poder continuar
            </div>
          </b-card-footer>
        </b-card>
      </tab-content>

      <tab-content
        title="Candidatos a Diaconado"
        icon="feather icon-thumbs-up"
        :before-change="validateCandidates2"
      >
        <b-card>
          <b-card-text>Ahora {{ firstNames }} {{ lastNames }}, seleccione {{ maxSelection2 }} opciones o menos</b-card-text>
          <b-card-text>
            <b-container>
              <b-row
                cols="1"
                cols-sm="2"
                cols-md="3"
                cols-lg="5"
              >
                <b-col
                  v-for="option in candidates2List"
                  :key="option.id"
                  sm
                  class="mb-4"
                >
                  <b-img-lazy
                    fluid-grow
                    rounded
                    :src="`https://firebasestorage.googleapis.com/v0/b/iepc-apps.appspot.com/o/diaconado%2F${option.id}.${option.imageExt}?alt=media`"
                    :alt="`No se ha recibido fotografía de ${option.name}`"
                    style="width: 35%; height: auto;"
                  />
                  <br>
                  <b-form-checkbox
                    v-model="candidates2"
                    :value="option.name"
                    size="lg"
                    switch
                  >
                    {{ option.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-container>
          </b-card-text>
          <b-card-footer>
            <div>
              {{ candidates2.length }} candidatos seleccionados de {{ candidates2List.length }} disponibles
            </div>
            <div
              v-if="candidates2.length > maxSelection2"
              class="text-danger"
            >
              Debe seleccionar {{ maxSelection2 }} candidatos o menos para poder continuar
            </div>
          </b-card-footer>
        </b-card>
      </tab-content>

      <tab-content
        title="Resumen de votación"
        icon="feather icon-file-text"
      >
        <b-card>
          <b-card-text>{{ firstNames }} {{ lastNames }}, estos son los candidatos por los que ha votado</b-card-text>
          <b-card-text>
            <h2 class="my-1">
              Consistorio
            </h2>
            <b-container>
              <b-row
                cols="1"
                cols-sm="2"
                cols-md="3"
                cols-lg="5"
              >
                <b-col
                  v-for="option in selected1List"
                  :key="option.id"
                  sm
                  class="mb-4"
                >
                  <b-img-lazy
                    fluid-grow
                    rounded
                    :src="`https://firebasestorage.googleapis.com/v0/b/iepc-apps.appspot.com/o/consistorio%2F${option.id}.${option.imageExt}?alt=media`"
                    :alt="`No se ha recibido fotografía de ${option.name}`"
                    style="width: 35%; height: auto;"
                  />
                  <br>
                  <b-form-checkbox
                    v-model="candidates1"
                    :value="option.name"
                    size="lg"
                    switch
                    disabled
                  >
                    {{ option.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-container>
          </b-card-text>
          <b-card-text>
            <h2 class="my-1">
              Diaconado
            </h2>
            <b-container>
              <b-row
                cols="1"
                cols-sm="2"
                cols-md="3"
                cols-lg="5"
              >
                <b-col
                  v-for="option in selected2List"
                  :key="option.id"
                  sm
                  class="mb-4"
                >
                  <b-img-lazy
                    fluid-grow
                    rounded
                    :src="`https://firebasestorage.googleapis.com/v0/b/iepc-apps.appspot.com/o/diaconado%2F${option.id}.${option.imageExt}?alt=media`"
                    :alt="`No se ha recibido fotografía de ${option.name}`"
                    style="width: 35%; height: auto;"
                  />
                  <br>
                  <b-form-checkbox
                    v-model="candidates2"
                    :value="option.name"
                    size="lg"
                    switch
                    disabled
                  >
                    {{ option.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-container>
          </b-card-text>
        </b-card>
      </tab-content>
    </form-wizard>
    <div
      v-if="voteSent"
      class="text-center"
    >
      <b-img
        class="mt-5"
        rounded
        :src="require('../assets/images/logo/logo.png')"
        alt="IEPC"
      />
      <h2
        class="my-5"
      >
        Elecciones {{ electionYear }}
      </h2>
      <h4
        class="mb-5"
      >
        Gracias por emitir su voto
      </h4>
      <p>
        Si vota de nuevo con el mismo nombre, se tomará en cuenta el último que haya emitido
      </p>
      <b-button
        variant="success"
        @click="goToHome"
      >
        Nuevo voto
      </b-button>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import {
  BCard, BCardText, BCardFooter, BFormInput, BFormInvalidFeedback, BFormCheckbox, BContainer, BRow, BCol, BImg, BImgLazy, BButton,
} from 'bootstrap-vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { app, db } from '@/firebaseConfig'

export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BCardText,
    BCardFooter,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BContainer,
    BRow,
    BCol,
    BImg,
    BImgLazy,
    BButton,
  },
  data() {
    return {
      votePosted: false,
      firstNames: '',
      lastNames: '',
      candidates1: [],
      candidates2: [],
    }
  },
  computed: {
    electionYear() {
      return this.$store.getters['fastway/getParameter']('election').year
    },
    electionOpen() {
      return this.$store.getters['fastway/getParameter']('election').open
    },
    voteSent() {
      return (this.electionOpen && this.votePosted)
    },
    acceptVote() {
      return (this.electionOpen && !this.votePosted)
    },
    firstNamesError() {
      return (this.firstNames === '' ? 'Debe especificar sus nombres ' : '')
    },
    lastNamesError() {
      return (this.lastNames === '' ? 'Debe especificar sus apellidos ' : '')
    },
    firstNamesBlank() {
      return (this.firstNamesError !== '')
    },
    lastNamesBlank() {
      return (this.lastNamesError !== '')
    },
    maxSelection1() {
      let max = 0
      const position = this.$store.state.fastway.positions.filter(p => String(p.id) === '1')
      if (position.length > 0) {
        max = position[0].maxSelections
      }
      return max
    },
    maxSelection2() {
      let max = 0
      const position = this.$store.state.fastway.positions.filter(p => String(p.id) === '2')
      if (position.length > 0) {
        max = position[0].maxSelections
      }
      return max
    },
    candidates1List() {
      return this.$store.state.fastway.candidates1
    },
    candidates2List() {
      return this.$store.state.fastway.candidates2
    },
    selected1List() {
      const self = this
      return this.candidates1List.filter(candidate => self.candidates1.some(f => f === candidate.name))
    },
    selected2List() {
      const self = this
      return this.candidates2List.filter(candidate => self.candidates2.some(f => f === candidate.name))
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    formSubmitted() {
      const data = {
        electionYear: this.electionYear,
        date: app.firebase.firestore.FieldValue.serverTimestamp(),
        firstNames: this.firstNames,
        lastNames: this.lastNames,
        selections1: this.selected1List,
        selections2: this.selected2List,
      }
      db.collection('votes')
        .add(data)
        .then(() => {
          this.votePosted = true
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al guardar voto',
              text: error.message,
              icon: 'AwardIcon',
              variant: 'danger',
            },
          }, 10000)
        })
    },
    validateName() {
      return (this.firstNames !== '' && this.lastNames !== '')
    },
    validateCandidates1() {
      return (this.candidates1.length <= this.maxSelection1)
    },
    validateCandidates2() {
      return (this.candidates2.length <= this.maxSelection2)
    },
    goToHome() {
      this.firstNames = ''
      this.lastNames = ''
      this.candidates1 = []
      this.candidates2 = []
      this.votePosted = false
    },
  },
}
</script>

<style>

</style>
